import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const StyledWrapper = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

const StyledRow = styled.li<{ $secondary?: boolean }>`
  border-bottom: 1px solid ${({ theme }) => theme.wash};
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  :last-of-type {
    border-bottom: 0;
  }

  ${({ $secondary }) =>
    $secondary &&
    css`
      padding: 5px 0 1px;
    `}
`;

const StyledCount = styled.span`
  margin-right: 10px;
`;

const StyledName = styled.span<{ $secondary?: boolean }>`
  font-weight: ${({ theme }) => theme.fontLight};
  margin-right: 10px;

  ${({ $secondary }) =>
    $secondary &&
    css`
      font-weight: ${({ theme }) => theme.fontBold};
    `}
`;

const StyledValue = styled.span`
  font-weight: ${({ theme }) => theme.fontBold};
  text-align: right;
`;

const Table: FC<Props> = ({
  values,
  withCount,
  summary,
  className,
  ...props
}) => (
  <StyledWrapper className={className} {...props}>
    {values.map(({ name, value }, index) => {
      const currentIndex =
        typeof withCount === 'number' ? withCount + index : index + 1;

      return (
        <StyledRow $secondary={!!withCount} key={index}>
          <StyledName>
            {withCount && (
              <StyledCount>
                {currentIndex <= 9 ? `0${currentIndex}` : currentIndex}
              </StyledCount>
            )}
            {name}
            {!withCount && ':'}
          </StyledName>
          <StyledValue>{value}</StyledValue>
        </StyledRow>
      );
    })}
    {summary && (
      <StyledRow>
        <StyledName $secondary>{summary.name}:</StyledName>
        <StyledValue>{summary.value}</StyledValue>
      </StyledRow>
    )}
  </StyledWrapper>
);

interface Props {
  values: { name: string; value: string }[];
  withCount?: boolean | number;
  summary?: { name: string; value: string };
  className?: string;
}

export default Table;
