import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { BasicTemplate } from '@templates/BasicTemplate';
import Button from '@components/atoms/Button/Button';
import arrowLeftIcon from '@iconify-icons/la/arrow-left';
import downloadIcon from '@iconify-icons/la/download-solid';
import Table from '@components/atoms/Table/Table';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Houses from '@components/organisms/Houses/Houses';
import { graphql, Link } from 'gatsby';
import { ChildImageSharpImage } from '../types/childImageSharpImage';
import { goToSection } from '@utils/goToSection';
import { Availability } from '../types/availability';

const StyledBasicTemplate = styled(BasicTemplate)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 5%;
`;

const StyledHeaderWrapper = styled.div`
  padding-top: 60px;
  width: 90%;
  margin: 25px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1025px) {
    padding-top: 120px;
    margin: 60px auto;
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  max-width: 1800px;

  @media (min-width: 309px) {
    justify-content: space-between;
  }
`;

const StyledLine = styled.span`
  display: block;
  width: 100%;
  height: 6px;
  border-radius: 99px;
  background: ${({ theme }) => theme.primary};
  margin: 15px 0;
  max-width: 1800px;

  @media (min-width: 1024px) {
    height: 10px;
    margin: 40px 0;
  }
`;

const StyledButton = styled(Button).attrs(() => ({
  tertiary: true,
}))<{
  href?: string;
  target?: string;
  rel?: string;
  state?: any;
}>``;

const StyledHeadline = styled.h1`
  text-align: center;
  font-size: 2.4rem;
  margin: 10px 0 0;

  @media (min-width: 1025px) {
    font-size: 4.5rem;
    margin: 15px 0 0;
  }
`;

const StyledStatus = styled.p<{ $availability: Availability }>`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontLight};
  font-size: 2rem;
  margin: 15px 0 0;
  position: relative;
  padding-bottom: 15px;

  ::before {
    width: 50%;
    height: 4px;
    background: ${({ theme }) => theme.primary};
    border-radius: 99px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    content: '';

    @media (min-width: 1025px) {
      height: 6px;
      width: 70%;
    }
  }

  span {
    font-weight: ${({ theme }) => theme.fontBold};
    color: ${({ theme }) => theme.primary};
  }

  @media (min-width: 1025px) {
    font-size: 2.4rem;
    padding-bottom: 20px;
  }

  ${({ $availability }) =>
    $availability === Availability.UNAVAILABLE
      ? css`
          ::before {
            background: ${({ theme }) => theme.unavailable};
          }
          span {
            color: ${({ theme }) => theme.unavailable};
          }
        `
      : $availability === Availability.RESERVED &&
        css`
          ::before {
            background: ${({ theme }) => theme.reserved};
          }
          span {
            color: ${({ theme }) => theme.reserved};
          }
        `}
`;

const StyledPrice = styled.p`
  font-size: 2.4rem;
  font-weight: ${({ theme }) => theme.fontLight};
  text-align: center;
  margin: 40px 0;

  span {
    font-weight: ${({ theme }) => theme.fontBold};
    color: ${({ theme }) => theme.primary};
  }

  @media (min-width: 1025px) {
    font-size: 3.4rem;
    margin: 50px 0;
  }
`;

const StyledSwitchButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 85px;
  width: 100%;

  @media (min-width: 390px) {
    width: calc(100% - 50px);
    margin-right: -50px;
  }
`;

const StyledFloorsWrapper = styled.div`
  margin-bottom: 40px;

  @media (min-width: 1025px) {
    display: flex;
    justify-content: space-between;
    max-width: 1500px;
  }
`;

const StyledFloor = styled.div`
  @media (min-width: 1025px) {
    margin-right: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    :last-of-type {
      margin-right: 0;
    }
  }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  @media (min-width: 1025px) {
    width: 41vw;
    max-width: 700px;
  }
`;

const StyledSecondHeadline = styled.h2`
  font-size: 3.5rem;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontBold};
  margin: 40px 0 30px;

  @media (min-width: 1025px) {
    font-size: 4.5rem;
  }
`;

const StyledTable = styled(Table)<{ $withMargin?: boolean }>`
  width: 100%;
  max-width: 500px;

  ${({ $withMargin }) =>
    $withMargin &&
    css`
      margin-bottom: 60px;
    `}
`;

const StyledSwitchButton = styled(Button).attrs(() => ({ secondary: true }))<{
  $active?: boolean;
}>`
  font-weight: ${({ theme }) => theme.fontRegular};
  color: ${({ theme }) => theme.foreground};
  margin-bottom: 15px;

  @media (min-width: 390px) {
    margin-right: 50px;
  }

  ${({ $active, theme }) =>
    $active &&
    css`
      background: ${theme.primary};
      color: ${theme.background};
      cursor: default;
      pointer-events: none;

      :active {
        transform: none;
      }
    `};
`;

const StyledContactButton = styled(Button).attrs(() => ({
  secondary: true,
}))<{ href: string }>`
  justify-content: center;
  text-align: center;
  margin-bottom: 40px;

  @media (min-width: 1025px) {
    margin-bottom: 160px;
  }
`;

const sumAreaOfRooms = (rooms: { name: string; area: number }[]) => {
  let sum = 0;

  rooms.forEach(({ area }) => (sum += area));
  const formattedSum = sum.toFixed(2);

  return formattedSum.replace('.', ',');
};

const mapRooms = (rooms: { name: string; area: number }[]) =>
  rooms.map(({ name, area }) => ({
    name,
    value: `${area.toString().replace('.', ',')} m2`,
  }));

const HouseTemplate: FC<Props> = ({ data }) => {
  const [is2dActive, set2dActive] = useState(false);

  const {
    number,
    availability,
    area,
    gardenArea,
    roomsCount,
    parkingsCount,
    deliveryDate,
    price,
    firstFloorRooms,
    atticRooms,
    replaceNumber,
  } = data.datoCmsHouse;

  return (
    <StyledBasicTemplate
      title={`Lokal ${replaceNumber?.toUpperCase() || number.toUpperCase()}`}
      header={() => (
        <StyledHeaderWrapper>
          <StyledButtonsWrapper>
            <StyledButton
              icon={arrowLeftIcon}
              forwardedAs={Link}
              to="/"
              state={{
                prevPath:
                  typeof window !== 'undefined' && window.location.pathname,
              }}
            >
              Powrót
            </StyledButton>
            <StyledButton
              icon={downloadIcon}
              forwardedAs="a"
              href={`/${number.toUpperCase()}.pdf`}
              target="_blank"
              rel="noopener"
            >
              Pobierz PDF
            </StyledButton>
          </StyledButtonsWrapper>
          <StyledLine />
          <StyledHeadline data-aos="fade-up">
            Lokal {replaceNumber?.toUpperCase() || number.toUpperCase()}
          </StyledHeadline>
          <StyledStatus
            $availability={availability}
            data-aos="fade-up"
            data-aos-delay="300"
          >
            Status: <span>{availability.toLowerCase()}</span>
          </StyledStatus>
        </StyledHeaderWrapper>
      )}
      changeStyleOffset={100}
    >
      <StyledTable
        values={[
          {
            name: 'Powierzchnia użytkowa',
            value: `${area.toString().replace('.', ',')} m2`,
          },
          {
            name: 'Powierzchnia ogrodu',
            value: `ok. ${gardenArea.toString().replace('.', ',')} m2`,
          },
          { name: 'Ilość pokoi', value: roomsCount.toString() },
          { name: 'Miejsc parkingowych:', value: parkingsCount.toString() },
          { name: 'Termin oddania', value: deliveryDate },
        ]}
        data-aos="fade-up"
        data-aos-delay="600"
      />

      <StyledPrice>
        Cena:{' '}
        <span>
          {new Intl.NumberFormat('pl-PL', {
            style: 'currency',
            currency: 'PLN',
            minimumFractionDigits: 0,
          }).format(price)}
        </span>
      </StyledPrice>

      <StyledSwitchButtonsWrapper>
        <StyledSwitchButton
          $active={!is2dActive}
          onClick={is2dActive ? () => set2dActive(false) : undefined}
        >
          Wizualizacja
        </StyledSwitchButton>
        <StyledSwitchButton
          $active={is2dActive}
          onClick={!is2dActive ? () => set2dActive(true) : undefined}
        >
          Rzut 2D
        </StyledSwitchButton>
      </StyledSwitchButtonsWrapper>

      <StyledFloorsWrapper>
        <StyledFloor>
          <StyledGatsbyImage
            alt="Oleńki Park"
            image={
              getImage(
                !is2dActive
                  ? (data.firstFloor as any)
                  : (data.firstFloor2d as any)
              ) as IGatsbyImageData
            }
          />

          <StyledSecondHeadline>Parter</StyledSecondHeadline>

          <StyledTable
            values={mapRooms(firstFloorRooms)}
            withCount
            summary={{
              name: 'Suma powierzchni',
              value: `${sumAreaOfRooms(firstFloorRooms)} m2`,
            }}
            $withMargin
          />
        </StyledFloor>

        <StyledFloor>
          <StyledGatsbyImage
            alt="Oleńki Park"
            image={
              getImage(
                !is2dActive ? (data.attic as any) : (data.attic2d as any)
              ) as IGatsbyImageData
            }
          />

          <StyledSecondHeadline>Poddasze</StyledSecondHeadline>

          <StyledTable
            values={mapRooms(atticRooms)}
            withCount={firstFloorRooms.length + 1}
            summary={{
              name: 'Suma powierzchni',
              value: `${sumAreaOfRooms(atticRooms)} m2`,
            }}
          />
        </StyledFloor>
      </StyledFloorsWrapper>

      <StyledContactButton
        forwardedAs="a"
        onClick={(e: any) => goToSection(e, '#contact')}
        href="#contact"
      >
        Skontaktuj się
      </StyledContactButton>

      <Houses image={data.overhead} />
    </StyledBasicTemplate>
  );
};

interface Props {
  data: {
    overhead: ChildImageSharpImage;
    attic: ChildImageSharpImage;
    attic2d: ChildImageSharpImage;
    firstFloor: ChildImageSharpImage;
    firstFloor2d: ChildImageSharpImage;
    datoCmsHouse: {
      number: string;
      replaceNumber?: string;
      area: number;
      availability: Availability;
      deliveryDate: string;
      firstFloorRooms: { name: string; area: number }[];
      gardenArea: number;
      parkingsCount: number;
      price: number;
      atticRooms: { name: string; area: number }[];
      roomsCount: number;
    };
  };
}

export const query = graphql`
  query ($houseNumber: String!) {
    datoCmsHouse(number: { eq: $houseNumber }) {
      number
      replaceNumber
      area
      availability
      deliveryDate(formatString: "DD MMMM YYYY", locale: "PL")
      firstFloorRooms {
        name
        area
      }
      gardenArea
      parkingsCount
      price
      atticRooms {
        name
        area
      }
      roomsCount
    }

    overhead: file(name: { eq: "overhead" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    attic: file(name: { eq: "attic" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    firstFloor: file(name: { eq: "first_floor" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    attic2d: file(name: { eq: "2d_attic" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
        )
      }
    }
    firstFloor2d: file(name: { eq: "2d_first_floor" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
        )
      }
    }
  }
`;

export default HouseTemplate;
